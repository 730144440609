<div class="card border-0 shadow-sm">
  <div class="card-body">
    <h5 class="card-title col-xl-6 col-lg-6 col-md-6  col-sm-12 col-12 userDropDown">
      <ng-select (ngModelChange)="getSelectedGroupId($event)" [(ngModel)]="selectedGroupId" bindLabel="groupId"
        placeholder="Select Group" [searchable]="true" [clearable]="true">
        <ng-option value="{{g.groupId}}" *ngFor="let g of groupList; let i = index">
          <option>{{g.groupName}}</option>
        </ng-option>
      </ng-select>
    </h5>
    <ng-container>
      <!-- //List tab start  -->
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon tabPointer">pie_chart</mat-icon>&nbsp;
            Group stats
          </ng-template>
              <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsPiechart" [(update)]="updateFlag">
              </highcharts-chart>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">bar_chart</mat-icon>&nbsp;
            Resource Stats
          </ng-template>
          <div class="col-xl-6 col-lg-6 col-md-6  col-sm-12 col-12 float-right">
            <ng-select [(ngModel)]="seletedUser" (ngModelChange)="getSelectedUserId($event)" bindLabel="userName"
              placeholder="Select User" [searchable]="true" [clearable]="true">
              <ng-option value="{{u.userId}}" *ngFor="let u of groupMemberData; let i = index">
                <option>{{u.userName}}</option>
              </ng-option>
            </ng-select>
          </div>
            <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsResource"></highcharts-chart>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">leaderboard</mat-icon>&nbsp;
            Top achievers
          </ng-template>
          <div class="fixTableHead">
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Task completed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let t of resourceByTaskCountList; let i=index">
                  <td>{{t.userName}}</td>
                  <td [ngClass]="{'highlight': highestTask == t.doneTaskCount && highestTask  > 0}">{{t.doneTaskCount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

      </mat-tab-group>
    </ng-container>
  </div>
</div>
