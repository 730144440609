<head>
    <meta charset="UTF-8">
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"> -->
    <meta property="og:title" content="Track2Excel- Task Tracking , Task Management , Project Task " />
    <meta property="og:description" content="Task2Excel- Simple & easy to use Task management app, Collaborate as a team with different group, Support for corporate group creation with custom domain, Ability to create recurring todo item, Easy to note editor and upload text functionality, Automated reminders for tasks" />
    <meta property="og:image" content="asset/img/logo/favicon.png" />
    <meta name="theme-color" content="#0745b2" />
    <meta name="viewport" content="width=device-width">
<meta name="viewport" content="initial-scale=1">
    <!-- Windows Phone -->
    <meta name="msapplication-navbutton-color" content="#0745b2">
    <!-- iOS Safari -->
    <meta name="apple-mobile-web-app-status-bar-style" content="#0745b2">
    <title>Track2Excel</title>
    <!--::::: FABICON ICON :::::::-->
    <link rel="icon" href="asset/img/logo/favicon.png">
</head>
  <!--::::: PRELOADER :::::::-->
  <body class="theme-3">
  <div class="mesh-loader">
    <div class="set-one">
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
    <div class="set-two">
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
</div>
<!--::::: PRELOADER :::::::-->


<!--::::: HEADER AREA START :::::::-->
<header>
    <div class="header_area header3 header_absolute primary_bg" id="header">
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-2 align-self-center">
                    <div class="logo">
                        <a href="#home"><img src="assets/asset/img/logo/track2excel_logo.png" alt="Track2Excel Logo"   class="logo"></a>
                    </div>
                </div>
                <div class="col-6 col-lg-8 align-self-center">
                    <div class="main_menu d-none d-lg-block text-right" style="line-height: 1px;">
                        <ul id="nav_menu" class="scroll">
                            <li auto_trigger><a href="#home">Home</a> </li>
                            <!-- <li><a href="#features">Features</a></li> -->
                            <li><a href="#features">Features</a></li>
                            <li><a href="#contact">Contact Us</a></li>
                            <li><a href="javascript:void(0)" routerLink="/login"  target="_blank" >Login</a></li>
                        </ul>
                    </div>
                    <div class="mobile_menu d-lg-none"></div>
                </div>

            </div>
        </div>
    </div>
</header>
<!--::::: HEADER AREA END :::::::-->

<!--::::: WELCOME AREA START :::::::-->
<div class="welcome_area primary_bg" id="home">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 align-self-center">
                <div class="title2" data-scroll>
                    <h1 data-splitting class="title2_con"><span class="title2_con">Simple to use </span> task management,todo tracker app
                    </h1>
                    <div class="space-10"></div>
                    <h6 data-splitting>Collaborate with team for task management or track your todo items. <br/>Simple no fuss and effective application and it's totally free.
                    </h6>
                    <div class="space-40"></div>

                    <div class="btn2_group">
                        <a href="https://track2excel.com/auth/sign-up" target="_blank" class="btn4">SignUp Now</a>
                        <!-- <a class="play_btn btn5" href="https://www.youtube.com/watch?v=xcJtL7QggTI">Watch Video <i class="fas fa-play"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="illas_box">
        <div class="illas_wrap wow fadeInRight">
            <img src="assets/asset/img/hero/banner.png" alt="Simple to use task management" class="track">
            <!-- <img class="illas2" src="asset/img/hero/hero311.png" alt=""> -->
        </div>
    </div>
</div>
<!--::::: WELCOME AREA END :::::::-->

<!--::::: LOGO AREA START :::::::-->


<!--::::: FEATURE AREA START :::::::-->
<div class="feature_area section-padding section--padding-features pt-2"  id="features">
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-12">
                <div class="feature3_img_left_mobile feature3_mobile">
                    <div class="feature3_img_left_wrap managementImg">
                        <img src="assets/asset/img/feature/collaborate-with-task-management.png"  alt="Collaborate with Task Management">
                        <!-- <img class="shape31" src="asset/img/shape/oval1.png" alt=""> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 align-self-center">
                <div class="heading heading-first">
                    <h1>Collaborate with Task Management</h1>
                    <p>There are many task management app, track2excel is intented to replace your notepad with cool features like recursive task, simple notepad view.</p>
                    <div class="space-30"></div>

                </div>
            </div>
        </div>
    </div>
    <div class="feature3_img_left">
        <div class="feature3_img_left_wrap">
            <img src="assets/asset/img/feature/collaborate-with-task-management.png" alt="Collaborate with Task Management" class="shape">
            <!-- <img class="shape31" src="asset/img/shape/oval1.png" alt="shape"> -->
        </div>
    </div>
</div>
<!--::::: FEATURE AREA END :::::::-->

<!--::::: FEATURE AREA START :::::::-->
<!-- <div class="space-100 feature_space"></div> -->
<div class="feature_area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="feature3_img_right_mobile">
                    <div class="feature3_img_right_wrap">
                        <img src="assets/asset/img/feature/todo-tracker.png" alt="TODO Tracker">
                        <!-- <img class="shape32" src="asset/img/shape/oval1.png" alt="oval"> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 align-self-center">
                <div class="heading heading-second">
                    <h1>TODO tracker</h1>
                    <p>Create TODO items in notepad view and start tracking with reminders etc. Use it for personal and official purpose, many modes of reminders.</p>
                    <div class="space-30"></div>
                    <!-- <a href="#" class="btn4">Try for free</a> -->
                </div>
            </div>

        </div>
    </div>
    <div class="feature3_img_right">
        <div class="feature3_img_right_wrap">
            <img src="assets/asset/img/feature/todo-tracker.png" alt="TODO Tracker" class="shape1">
            <!-- <img class="shape32" src="asset/img/shape/oval1.png" alt="oval1"> -->
        </div>
    </div>

</div>
<!--::::: FEATURE AREA END :::::::-->
<div class="space-100 feature_space "></div>
<!-- <div class="space-100 feature_space "></div> -->
<!--::::: MORE FEATURE AREA START :::::::-->
<div class="more_feature_area section-padding primary_bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 text-center m-auto">
                <div class="heading hbm">
                    <h1>More features</h1>
                    <!-- <p>Classified advertising is a form of advertising that is particularly common in newspapers and other periodicals. </p> -->
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                        <img src="assets/asset/img/icons/notpad-editor.png" alt="Notpad Editor Feature">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Notepad editor</h6>
                        <p>Simple notepad editor, make notes and it will create multiple tasks immediately.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                        <img src="assets/asset/img/icons/recursive-tasks.png" alt="Recursive Tasks Feature ">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Recursive Tasks</h6>
                        <p>You can create daily/weekly/monthly tasks once and app will create repeated tasks.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                        <img src="assets/asset/img/icons/corporate-usage.png" alt="Corporate Usage Feature">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Corporate Usage</h6>
                        <p>Use your company id for registration and you can collaborate with colleague. Corporate users get auto populated users and many features.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                        <img src="assets/asset/img/icons/offline-mode.png" alt="Offline Mmode Feature">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Offline Mode</h6>
                        <p>You can create TODO/Task item txt file and upload it on app to create tasks.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                         <img src="assets/asset/img/icons/automated-reminder.png" alt="Automated Reminder Feature">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Automated Reminder</h6>
                        <p>Get email reminder for overdue item and assigned items. Working on other integration points.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="single_more_feature">
                    <div class="icon_box more_feature_icon">
                        <img src="assets/asset/img/icons/groups.png" alt="Groups Feature">
                    </div>
                    <div class="content_box more_feature_content">
                        <h6>Groups</h6>
                        <p>Create unlimited groups for different project. Save most commonly used group as favorite</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--::::: MORE FEATURE AREA END :::::::-->

<div class="cta_area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 text-center m-auto">
                <div class="heading hbm">
                    <h1>Get ready to Excel</h1>
                    <p>Track your tasks effectively with simple to use application. All at no cost!</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7 text-center m-auto">
                <div class="heading hbm">
                    <a href="https://track2excel.com/auth/sign-up" target="_blank" class="contactBtn">Signup Now &nbsp;&nbsp;<i class="fa fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--::::: CTA AREA START :::::::-->
<section id="contact" class="pt-4">
    <div class="cta_area section-padding primary_bg">
        <div class="container">
            <div class="row">

                <div class="col-lg-7 text-center m-auto">
                    <div class="heading hbm">
                        <h1>Contact Us</h1>
                        <p>Have a question, feel free to buzz us!</p>
                    </div>
                </div>
                <div class="col-lg-7 text-center m-auto">
                <form [formGroup]="contactUs" (ngSubmit)="onSubmit(contactUs)">
                    <div class="row pt-5">
                        <div class="col-lg-6 new-nb contact-input mb-3">
                            <div class="input-group contact-input ">

                                <input type="text" formControlName="userName" id="userName" class="form-control" placeholder="Name *"
                                    name="name"     [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"/>
                                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                        <div *ngIf="f.userName.errors.required"> User Name is required</div>
                                    </div>
                            </div>
                            <span class="error-text"></span>
                        </div>
                        <div class="col-lg-6 new-nb mb-3">
                            <div class="input-group contact-input">

                                <input type="email" formControlName="userEmail"  class="form-control" placeholder="Email * "
                                required [ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }"/>
                                <mat-error *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                                    <mat-error *ngIf="f.userEmail.errors.required">Email is required</mat-error>
                                    <mat-error *ngIf="f.userEmail.errors.email">Email must be a valid email address</mat-error>
                                </mat-error>
                            </div>
                            <span class="error-text"></span>

                        </div>
                        <div class="col-lg-6 new-nb mb-3">
                            <div class="input-group contact-input">

                                <input type="text" onlyNumber  formControlName="contactNo" id="number" class="form-control onlynumbers" placeholder="Mobile  "
                                    name="contactNo"  [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" >
                                    <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                                    <mat-error *ngIf="onlyNumber">
                                    </mat-error>
                                </div>

                            </div>
                            <span class="error-text"></span>

                        </div>
                        <div class="col-lg-6 new-nb mb-3">

                            <div class="input-group contact-input ">

                                <input type="text" formControlName="subject" class="form-control" placeholder="Subject *"
                                    id="subject" name="subject"  [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                                    <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject.errors.required"> Subject  is required</div>
                                    </div>

                            </div>
                            <span class="error-text"></span>
                        </div>

                        <div class="col-lg-12">

                            <div class="form-group">
                                <textarea class="form-control" formControlName="message" id="message" placeholder="Description *" rows="3"
                                        name="message"></textarea>
                                <span class="error-text"></span>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="commonMessage"></div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class=" btn2_group text-center">

                                <button [disabled]="loadingBtn" disabled class="contactBtn" >
                                    <span *ngIf="loadingBtn" class="spinner-border spinner-border-sm mr-1"></span>
                                    Send</button>
                            </div>
                        </div>

                    </div>

                    </form>

                </div>
            </div>
        </div>
        <!--::::: CTA AREA END :::::::-->
    </div>
</section>
<!--::::: FOOTER AREA START :::::::-->
<footer>
    <div class="footer footer1 section-padding2">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="single_footer">
                        <div class="footer_logo">
                            <a href="#home"><img src="assets/asset/img/logo/track2excel_logo.png" alt="Track2Excel Logo" class="logo"></a>
                        </div>
                        <p class="footer_pera">Track2Excel.com is a SaaS-based solution specially designed for
                            managing training institutes and coaching classes online.</p>

                        <div class="location links mt-1">
                            <h4>Location</h4>
                            <p>Esha House, Sudarshan Nagar, Chinchwad, Pune-411033</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg">
                    <div class="single_footer">
                        <h2>Links</h2>
                        <ul>
                            <li><a href="#home">Home</a></li>
                            <li><a href="#features">Features</a></li>
                            <!-- <li><a href="#features">Features</a></li> -->
                            <li><a href="#contact">Contact Us</a></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg">
                    <div class="single_footer">
                        <h2>Quick Links</h2>
                        <ul>
                            <li><a href="https://track2excel.com/login" target="_blank"> Login</a></li>
                            <li><a href="https://track2excel.com/auth/sign-up" target="_blank"> Signup</a></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg">
                    <div class="single_footer">
                        <div class="location links">
                            <h2>Contact</h2>
                            <ul>
                                <li><a href="tel:+919130081345">+91 9130081345 </a></li>
                            </ul>
                            <h4 class="footer_email">Email</h4>
                            <ul>
                                <li><a href="mailto:sales@gunadhyasoft.com">sales@gunadhyasoft.com</a></li>


                            </ul>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-lg-8">
                    <p>Copyright &copy;<span id="year"> {{currentYear}} </span> <a target="_blank" class="copyrightClr" href="https://www.gunadhyasoft.com/"> Gunadhya software solution Pvt Ltd</a> All rights reserved.</p>
                </div>
                <div class="col-sm-3 col-lg-4">
                    <div class="footer_social">
                        <div class="social1">
                            <ul>
                                <li><a href="https://www.facebook.com/track2excel" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.linkedin.com/showcase/tracktoexcel/?viewAsMember=true" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</footer>

<!--::::: FOOTER AREA END :::::::-->


<!--::::: ALL JS FILES :::::::-->
<!--

<script type="text/javascript">
                        function validateEmail(email) {
                            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                            return re.test(email);
                        }

                        function validate_number(event) {
                            var key = window.event ? event.keyCode : event.which;
                            if (event.keyCode == 8 || event.keyCode == 46
                                    || event.keyCode == 37 || event.keyCode == 39) {
                                return true;
                            } else if (key < 48 || key > 57) {
                                return false;
                            } else
                                return true;
                        }

                        function isTextwithsapce(name) {
                            var regex = /^[a-zA-Z ]+$/;
                            return regex.test(name);
                        }
                        function isMobileNumber(mobile) {
                            var pattern = new RegExp(/^[-+]*[0-9][-+0-9]*$/);
                            return pattern.test(mobile);
                        }
                        function validateContactForm() {
                            ///company name validation
                            $isError = false;
                            $name = $.trim($('#user_name').val());
                            $email = $("#email").val();
                            $subject = $.trim($("#sub").val());
                            $message = $.trim($("#message").val());
                            $number = $.trim($("#number").val());



                            if ($name == "") {
                                $("#user_name").addClass("error");
                                $("#user_name").parent().siblings(".error-text").text("Please enter name ");
                                $("#user_name").parent().siblings(".error-text").fadeIn();
                                $isError = true;
                            } else if ($name != "" && !isTextwithsapce($name)) {
                                $isError = true;
                                $("#user_name").addClass("error");
                                $("#user_name").parent().siblings(".error-text").text("Please enter charcater only ");
                                $("#user_name").parent().siblings(".error-text").fadeIn();
                            } else {
                                $("#user_name").removeClass("error");
                                $("#user_name").parent().siblings(".error-text").fadeOut();
                            }


                            if ($email == "") {
                                $isError = true;
                                $("#email").addClass("error");
                                $("#email").parent().siblings(".error-text").text("Please enter email ");
                                $("#email").parent().siblings(".error-text").fadeIn();
                            } else if ($email != "" && !validateEmail($email)) {
                                $isError = true;
                                $("#email").addClass("error");
                                $("#email").parent().siblings(".error-text").text("Please enter valid email ");
                                $("#email").parent().siblings(".error-text").fadeIn();

                            } else {
                                $("#email").removeClass("error");
                                $("#email").parent().siblings(".error-text").fadeOut();
                            }



                            if ($subject == "") {
                                $isError = true;
                                $("#sub").addClass("error");
                                $("#sub").parent().siblings(".error-text").text("Please enter subject ");
                                $("#sub").parent().siblings(".error-text").fadeIn();
                            } else {
                                $("#sub").removeClass("error");
                                $("#sub").parent().siblings(".error-text").fadeOut();
                            }
                            if ($number != "" && !isMobileNumber($number)) {
                                $isError = true;
                                $("#number").addClass("error");
                                $("#number").parent().siblings(".error-text").text("Please enter valid mobile number ");
                                $("#number").parent().siblings(".error-text").fadeIn();
                            } else {
                                $("#number").removeClass("error");
                                $("#number").parent().siblings(".error-text").fadeOut();
                            }
                            if ($message == "") {
                                $("#message").addClass("error");
                                $("#message").siblings(".error-text").text("Please enter description ");
                                $("#message").siblings(".error-text").fadeIn();
                                $isError = true;
                            } else if ($message != "" && $message.length < 10) {
                                $("#message").addClass("error");
                                $("#message").siblings(".error-text").text("Please enter minimum 10 characters ");
                                $("#message").siblings(".error-text").fadeIn();
                                $isError = true;
                            } else {
                                $("#message").removeClass("error");
                                $("#message").siblings(".error-text").fadeOut();
                            }
                            return $isError;
                        }


                        $(document).ready(function () {
                            $('#submitContactBtn').click(function (e) {
                                $(this).prop('disabled', true);
                                $(".commonMessage").html('<img src="asset/img/custom-loader.gif" alt="loader" >');
                                if (validateContactForm()) {
                                    $(this).prop('disabled', false);
                                    $(".commonMessage").html('');
                                    e.preventDefault();
                                } else {
                                    $name = $.trim($('#user_name').val());
                                    $email = $("#email").val();
                                    $subject = $.trim($("#sub").val());
                                    $message = $.trim($("#message").val());
                                    $number = $.trim($("#number").val());
                                    $.ajax({
                                        type: "POST",
                                        url: "https://api.track2excel.com/1.0/front/contactus",
                                        dataType: 'json',
                                        async: false,
                                        data: {
                                            userName: $name,
                                            userEmail: $email,
                                            contactNo: $number,
                                            subject: $subject,
                                            message: $message
                                        },
                                        success: function (data, textStatus, jqXHR) {
                                            $(this).prop('disabled', false);
                                            $(".commonMessage").html('');
                                            if (data['status'] == 'success') {
                                                $(".commonMessage").html('<h6 class="text-success">' + data['message'] + '</h6>');
                                            } else {
                                                $(".commonMessage").html('<h6 class="text-danger">' + data['message'] + '</h6>');
                                            }
                                        },
                                        error: function (data, status, err) {
                                            $(this).prop('disabled', false);
                                            $(".commonMessage").html('<h6 class="text-danger">Message not sent, Please try again</h6>');
                                        }

                                    });
                                }
                            });

                            $(".onlynumbers").keypress(function (e) {
                                //if the letter is not digit then display error and don't type anything
                                if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                                    //display error message
                                    return false;
                                }
                            });
                        });


</script> -->
</body>
