import { Directive, ElementRef,OnInit } from '@angular/core';

@Directive({
  selector: '[appCustomManipulationText]'
})
export class CustomManipulationTextDirective implements OnInit {

  constructor(private elementRef: ElementRef) { }   

   innerHtmlValue = '';

   ngOnInit():void{
    this.innerHtmlValue = this.elementRef.nativeElement.innerText;
    this.elementRef.nativeElement.innerText = this.getManipulatedValue();
   }

  getManipulatedValue(){
    let value:any = this.innerHtmlValue;
      if(value.length > 150){
        return value.substr(0,150) + ' ...';
    }
    return value;
  }

}
