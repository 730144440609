<div class="signin-content">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-heading header-text">
                <img src="assets/img/Logo.png" alt="Track2Ecxel" />
            </div>
            <hr class="header-border">
            <div class="modal-body">
                <div class="text-center">
                    <h3>Reset Password </h3>
                </div>
                <form [formGroup]="resetpassForm" (ngSubmit)="onSubmit(resetpassForm)">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Enter email id" formControlName="userEmail"
                            required [ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }" />
                        <mat-error *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                            <mat-error *ngIf="f.userEmail.errors.required">Email is required</mat-error>
                            <mat-error *ngIf="f.userEmail.errors.pattern">Email must be a valid email address
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="password" placeholder="Password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
                            <mat-error *ngIf="f.password.errors.pattern">Password should be contain at least 6 in length and 1  Lowercase , Uppercase , Numbers, & Special characters. 
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Confirm password" class="form-control"
                            formControlName="conpassword"
                            [ngClass]="{ 'is-invalid': submitted && f.conpassword.errors }" />
                        <mat-error *ngIf="submitted && f.conpassword.errors" class="invalid-feedback">
                            <mat-error *ngIf="f.conpassword.errors.required">Confirm password is required</mat-error>
                            <mat-error *ngIf="f.conpassword.errors.mustMatch">Password and confirm password must match
                            </mat-error>
                        </mat-error>
                    </div>

                    <div class="form-group text-center">
                        <div class="box bg-1">
                            <button type="submit" [disabled]="loadingBtn" disabled
                                class="btn btn-xs border btn-default mr-1 track-btn-blue btn-md">Save</button>
                        </div>
                        <a href="javascript:void(0)" routerLink="/login" class="btn btn-link">Back to login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>