<app-menu></app-menu>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

            <!-- <div class=""> -->
                <div class="user-search">
                    <div class="row">
                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"> -->
                            <!-- <div class="row"> -->
                              <div class="col-md-12 col-lg-12 notification-search-wrapper d-flex align-items-center justify-space-between">

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" >
                                        <div class="input-group mb-2 border rounded-lg">
                                            <div class="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" class="btn btn-link text-primary">
                                                    <i class="fa fa-search iconColor"></i>
                                                </button>
                                            </div>
                                            <input type="search" placeholder="Search notification message" [(ngModel)]="term" aria-describedby="button-addon4"
                                                      class="form-control bg-none border-0"/>
                                        </div>
                                    </div>
                                <div class="col-lg-6 col-md-6 text-right" >
                                    <p class="maarAsreadColor pointer" style="display: inline-block ;"  (click)="markAsAllRead()"  mat-button color="warn">Mark all as read</p>
                                </div>
                              </div>

                                <!-- <div class="col-1 pointer"></div>
                                <div class="col-1 pointer"></div> -->
                            <!-- </div> -->
                        <!-- </div> -->


                        
                    </div>
                </div>
            <!-- </div> -->
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card-body">
                <h5 class="card-title">
                    
                </h5>
                <div class="table-responsive scrollbar invitationFixedHeight" style="overflow-x:auto;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" >Notification message</th>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let n of notificationList | filter:term">
                                <td>
                                    <mat-card-subtitle>{{n.message}}
                                    </mat-card-subtitle>
                                </td>
                                <td>{{ n.createdAt | date}}</td>
                                <td>
                                    <div  *ngIf="n.isRead == false else elseBlockRead">  
                                            <p mat-button class="maarAsreadColor pointer"   color="primary"  (click)="markRead(n.notificationId, n.moduleType)">Mark as read</p>
                                            <!-- <button mat-button color="warn" (click)="rejectInvitation(n. notificationId, n.moduleType)">Reject</button> -->
                                    </div>
                                    <ng-template #elseBlockRead>
                                        <p mat-button color="primary">Read </p>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p *ngIf="(ErrorMessage)">No notification found</p>
                </div>
            </div>

        </div>
        <!-- col-xl-12 end-->
    </div>
    <!-- row end-->
</div>
<!-- container-fluid end-->