<div class="card">
  <div class="card-body cardHeight">
    <h5 class="card-title  userDropDown">
      <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-12 pt-2">
          <span class="myTaskLabel ">My
            Tasks</span>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2">
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6  col-sm-12 col-12 float-right">
          <ng-select (change)="updateMyTaskList($event)" bindLabel="groupId" placeholder="Select Group" (ngModelChange)="getSelectedGroupId($event)"
            [(ngModel)]="globalSelectedGroup" [searchable]="true" [clearable]="true">
            <ng-option value="{{g.groupId}}" *ngFor="let g of groupList; let i = index">
              <option>{{g.groupName}}</option>
            </ng-option>
          </ng-select>
        </div>
      </div>
    </h5>
    <ng-container>
      <input type="search" *ngIf="(!errorMesg)" placeholder="Search task" [(ngModel)]="taskSearch" name="search"
        autocomplete="off" aria-describedby="button-addon4" class="form-control bg-none input-group-sm">
      <div class="myTaskList">
        <div class="list-group mt-2" *ngFor="let t of myTaskList | filter: taskSearch; let i=index">
          <div *ngIf="t.taskStatus != 'Done'">
            <div class=" list-group-item list-group-item-action flex-column align-items-start taskListfont">
              <div class="col-md-12 col-12">
                <p (click)="getTaskDetailsById(t.taskId)" appCustomManipulationText
                  [innerHTML]="t.taskDescription | noSanitize" class="desc-space"></p>
              </div>

              <div class="row">
                <div class="col-md-4 col-4">
                  <mat-chip #ddTrigger="matMenuTrigger" [ngClass]="{
                  'inProgressstatus':t.taskStatus == 'In Progress'
                }" [matMenuTriggerFor]="ss" (click)="taskStatusValue(t)"
                    class="myTaskChip chipColorStatus chipShadow taskOption mr-2" selected>{{t.taskStatus}}
                  </mat-chip>
                  <mat-menu #ss="matMenu" [overlapTrigger]="false">
                    <div (click)="cancelRemoveClick($event)" class="userStatusDropDown">
                      <ng-select [items]="dataList" bindValue="name" (change)="getTaskStatusValue($event,t)"
                        bindLabel="name" placeholder="Select status" [searchable]="true" [clearable]="false"
                        [(ngModel)]="selectedStatus">
                      </ng-select>
                    </div>
                  </mat-menu>
                </div>
                <div class="col-md-4 col-4">
                    <div   *ngIf="t.taskDueDate === null else showDeuDate">No due date</div>
                    <ng-template #showDeuDate>
                      <div [class.dueDatemyTask]="t.isTaskOverDue === true">{{t.taskDueDate | date: 'MMM dd'}}</div>
                    </ng-template>
                </div>
                <div class="col-md-4 col-4">
                  <mat-icon #ddTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="pp" (click)="taskPriorityValue(t)" [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }" aria-hidden="false"
                  class="taskIconColor" matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}" color="primary" aria-label="Example home icon">flag</mat-icon>
                  <mat-menu #pp="matMenu" [overlapTrigger]="false">
                    <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                      <mat-form-field class="mat-sizes">
                        <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                          (selectionChange)="getTaskPriorityValue($event,t)">
                          <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                            {{pp.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>

        </div>
        <p class="taskError" *ngIf="(errorMesg)">No task found</p>
      </div>
    </ng-container>
  </div>
</div>
