import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
@Pipe({
  name: 'noSanitize'
})
export class NoSanitizePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(html: string): SafeHtml {
    let value = html;


  const xValue =this.domSanitizer.bypassSecurityTrustHtml(value);
  return xValue;

    // return this.domSanitizer.bypassSecurityTrustHtml(html);

  }

}
