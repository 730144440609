<div class="signin-content">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-heading header-text">
        <img src="assets/img/Logo.png" alt="Track2Ecxel Logo" />

      </div>
      <hr class="header-border">
      <div class="modal-body">
        <div class="text-center">
          <h3 class="">Please login to continue</h3>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" role="form">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-user"></span>
              </span>
              <input type="text" class="form-control" placeholder="User email" formControlName="userEmail" required
                [ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }" />
              <mat-error *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                <mat-error *ngIf="f.userEmail.errors.required">User email is required</mat-error>
                <mat-error *ngIf="f.userEmail.errors.pattern">Email must be a valid email address</mat-error>
              </mat-error>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-lock"></span>
              </span>
              <input type="password" class="form-control" [type]="fieldTextType ? 'text':'password' " formControlName="password" required
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
              <div class="input-group-append">
                <span class="input-group-text">
                 
                  <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}" (click)="toggleFieldTextType()">  </i>
                </span>
              </div>
              <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
              </mat-error>
            </div>

          </div>

          <div class="form-group text-center">
            <div class="box bg-1">
              <button type="submit" [disabled]="loadingBtn" disabled
                class="btn btn-xs border btn-default mr-1 track-btn-blue btn-md">Login</button>
            </div>
            <a href="javascript:void(0)" routerLink="/auth/forgot-password" class="btn btn-link">Forget Password</a>
            <div class="sign-up">
              Don't have an account? <a href="javascript:void(0)" routerLink="/auth/sign-up">Create One</a>
            </div>
            <!-- <div class="card-block">
              <button class="btn btn-social-icon btn-google" (click)="signInWithGoogle()"><span class="fa fa-linkedin"></span></button>
            </div>
            <button
            class="btn btn-xs border btn-default mr-1 track-btn-blue btn-md" >Login</button> -->
            <!-- <button #loginRef type="button" (click)="signInWithGoogle()" class="btn btn-danger">Login with Google</button> -->

          </div>

        </form>
        <!-- <button class="btn btn-danger" type="button" (click)="signInWithGoogle()">
          Login with Google
        </button>
        <button (click)="login()">Login with LinkedIn</button> -->
      </div>
    </div>
  </div>

</div>
