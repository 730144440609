<ul class="list-group animate__fadeInDownBig animate__delay-2s">
  <li class="list-group-item" *ngIf="t.isClosed == false">
    <div class="row align-items-center" >
      <div class="col-6 pointer" (click)="editTaskDialog(t)">
        <pre class="desc-space" appCustomManipulationText [innerHTML]="t.taskDescription | noSanitize"></pre>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
        <div class="" *ngIf="t.taskStatus">
         <div class="pointer"  #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="ss" tooltip="{{tooltipTaskStatus}}" (click)="taskStatusValue(t)"> {{t.taskStatus}} </div>
        </div>
        <mat-menu #ss="matMenu" [overlapTrigger]="false">
          <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
            <mat-form-field class="mat-sizes">
              <mat-select placeholder="Selected Status" [(value)]="selectedStatus"
                (selectionChange)="getTaskStatusValue($event,t)">
                <mat-option *ngFor="let dl of dataList" [value]="dl.name">
                  {{dl.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-menu>

      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <div class="" *ngIf="t.userDetails.userName else showUserMsg">
          <span class="userIcon" matTooltipClass='user-tooltip' matTooltip="{{tooltipUserName}}"
            [tooltip]="getUserNameTooltip(t)" matTooltip="Assign user" #trigger="matMenuTrigger"
            [matMenuTriggerFor]="aa" (click)="taskUserChange(t)">
            {{t.userDetails.userName | shortName}}</span>
        </div>
        <mat-menu #aa="matMenu" [overlapTrigger]="false">
          <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
            <mat-form-field class="mat-sizes">
              <mat-label> {{isMaker ? "Select an option" : "Selected User"}}</mat-label>
              <mat-select [(value)]="selectedUser ? selectedUser : noAssignee"
                (selectionChange)="taskUserChangeByUser($event, t.taskId, t.taskDescription,t.taskStatus,t.taskDueDate)">
                <input class="input-pad" matInput placeholder="Select User" (keyup)="onKey($event.target.value)">
                <mat-option *ngFor="let item of groupMemberData" [value]="item.userId">
                  {{item.userName}}
                </mat-option>
                <mat-option value="noAssignee">
                  No Assignee
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-menu>
        <ng-template #showUserMsg>
          <div class="">
            <mat-icon class="delete2 defaultIconsClr" (click)="taskUserChange(t)" #ddTrigger="matMenuTrigger"
              [matMenuTriggerFor]="aa" matTooltip="Assign user">
              person</mat-icon>
          </div>
        </ng-template>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <div class="delete2" *ngIf="t.taskDueDate else showDeuDate">
          <mat-datepicker #picker3></mat-datepicker>
          <input [class.overDueColor]="t.isTaskOverDue === true" readonly matTooltipClass='red-tooltip'
            matTooltip="{{tooltipDueDate }}" [tooltip]="getDueDateTooltip(t)"
            (dateInput)="taskUserupdateByDueDate('input', $event,t)" value="{{t.taskDueDate | date: 'MMM dd'}}"
            type="text" (click)="picker3.open()" placeholder="{{t.taskDueDate | date: 'MMM dd'}}" matInput
            [matDatepicker]="picker3" class="dueDateInput delete2 pt-2" />
        </div>

        <ng-template #showDeuDate>
          <div class="d-flex delete2">
            <mat-icon (click)="picker2.open()" class="delete2 taskIconColor" matTooltip="Due Date">event</mat-icon>
            <mat-datepicker #picker2></mat-datepicker>
            <span>
              <input matTooltip="Due Date" readonly (dateInput)="taskUserupdateByDueDate('input', $event,t)"
                [min]="TodayDate" type="text" (click)="picker2.open()" matInput [matDatepicker]="picker2"
                class="dueDateInput delete2 defaultIconsClr" />
            </span>
          </div>
        </ng-template>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <mat-icon (click)="taskPriorityValue(t)" #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="dd" [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }" aria-hidden="false"  matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}"
        class="taskIconColor pointer" color="primary" aria-label="Example home icon">flag</mat-icon><span>{{taskPriority}}</span>

        <mat-menu #dd="matMenu" [overlapTrigger]="false">
          <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
            <mat-form-field class="mat-sizes">
              <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                (selectionChange)="getTaskPriorityValue($event,t)">
                <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                  {{pp.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-menu>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <button mat-icon-button [matMenuTriggerFor]="beforeTaskMenu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #beforeTaskMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="getTaskDetailsById(t.taskId)">
            <mat-icon class="delete2">visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-menu-item (click)="deleteTask(t.taskId)" *ngIf="t.isTaskOwner">
            <mat-icon class="delete2">delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item (click)="ClosedTask(t.taskId)" *ngIf="t.taskStatus == 'Done'">
            <mat-icon class="delete2">done</mat-icon>
            <span>Close task</span>
          </button>
        </mat-menu>
      </div>

    </div>
  </li>
</ul>
