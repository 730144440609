
<ul class="closed_task_list list-group animate__fadeInDownBig animate__delay-2s">
  <li class="list-group-item closed_task_list_item mb-2">
    <div class="row ">
      <div class="col-10 pointer" (click)="editTaskDialog(t)">
        <!-- <pre class="desc-space"
          [innerHTML]="(add3Dots(t.taskDescription,250)) |  noSanitize">{{add3Dots(t.taskDescription,250)}}</pre> -->
          <pre (click)="editTaskDialog(t)" appCustomManipulationText
              [innerHTML]="t.taskDescription | noSanitize" class="desc-space"></pre>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <mat-icon class="delete2" (click)="getTaskDetailsById(t.taskId)">visibility</mat-icon>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
        <div class="center-vertically" *ngIf="t.taskStatus">
          <div class="pointer"  #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="ss" tooltip="{{tooltipTaskStatus}}" (click)="taskStatusValue(t)"> {{t.taskStatus}} </div>
        </div>
        <mat-menu #ss="matMenu" [overlapTrigger]="false">
          <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
            <ng-select [items]="dataList" bindValue="name" (change)="getTaskStatusValue($event,t)" bindLabel="name"
              placeholder="Select status" [searchable]="true" [clearable]="false" [(ngModel)]="selectedStatus">
            </ng-select>
          </div>
        </mat-menu>
      </div>
    </div>
  </li>

</ul>
