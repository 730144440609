<div class="row">
  <div class="col-lg-12 col-md-12">
    <mat-card class="taskHeader px-3">
      <div class="comment-title">{{groupName}}</div>
      <div>
        <mat-icon class="closedIcon" (click)="close()">close</mat-icon>
      </div>
    </mat-card>
  </div>
  <div class="col-md-6">
    <!-- <div class="row"> -->
    <!-- <div class="col-lg-12"> -->
    <mat-card-content>

      <div class="card-body inner-div task-description custom-scroll">
        <div class="row">
          <div class="col-8">
            <h4 class="font-bold">Task Description :</h4>
          </div>
          <div class="col-4">
            <mat-icon [ngClass]="{'taskpriorityUrgent': taskPriority === 'Urgent', 'taskpriorityHigh' : taskPriority === 'High', 'taskpriorityNormal' : taskPriority === 'Normal' }" aria-hidden="false" matTooltip="Task Priority"
            class="taskIconColor" color="primary" aria-label="Example home icon">flag</mat-icon><span>{{taskPriority}}</span>
          </div>
        </div>

        <pre *ngIf="taskDescription" [innerHTML]="taskDescription |  noSanitize">{{taskDescription || "" }} </pre>
      </div>
    </mat-card-content>
    <!-- </div> -->

    <!-- </div> -->

  </div>
  <div class="col-md-6 card-body  comment-line inner-div custom-scroll">
    <h4 class="font-bold">Comments :</h4>
    <ul class="list-unstyled task-comment-box" *ngFor="let t of TaskDetails; let i=index">

      <li class="media my-4 ">
        <div class="center-vertically mr-3">
          <span class="userIcon" matTooltipClass='user-tooltip' matTooltip="{{tooltipUserNameOpen}}"
            (mouseenter)="getOpenUserNameTooltip(t)">{{t.userName | shortName}}</span>
        </div>
        <div class="media-body comment-msg mt-1">
          <pre class="desc-space mt-0 mb-1 contentMsg" [innerHTML]="t.commentText | noSanitize"></pre>
          <mat-icon (click)="deleteComment(t.taskCommentId)" matTooltip="Delete Comment">delete</mat-icon>
        </div>
        <span class="float-right comment-date d-flex align-items-center">{{t.createAt | date:'medium'}}</span>
      </li>

    </ul>

    <form class="text-center" [formGroup]="addCommentForm" (ngSubmit)="saveComment()"
      (keydown.enter)="$event.preventDefault()">
      <div class="form-group comment_input_wrapper">
        <input type="text" placeholder="Add Comment" class="comment-input" [ngClass]="{ 'is-invalid': submitted && a.taskComment.errors }"
        formControlName="taskComment" [mentionConfig]="mentionConfig" (keypress)="eventHandler($event)"
        [mention]="items" [(ngModel)]="taskText" id="taskText">
        <mat-error *ngIf="submitted && a.taskComment.errors" class="invalid-feedback">
          <mat-error *ngIf="a.taskComment.errors.required">Task Comment is required
          </mat-error>
        </mat-error>
      </div>
      <button type="submit" [disabled]="loadingBtn" disabled class="btn btn-xs border btn-default mr-1 track-btn-blue">
        Add
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </form>
  </div>

</div>
<hr>
<!-- footer details -->
<div class="footer-detail row px-3 align-items-center">
  <div class="col-md-4 col-lg-4 ">
    <mat-card-content>
      <mat-card-subtitle class="">Details :-<span class="float-right">
          <mat-card-subtitle>
            <small> Created By<span class="userInfoColor"> {{createdBy}} </span> on <span
                class="userInfoColor">{{createdAt | date}}</span>
            </small>
          </mat-card-subtitle>
        </span>
      </mat-card-subtitle>
    </mat-card-content>
  </div>

  <div class="col-md-8 col-lg-8">
    <div class="col-lg-4 col-xl-4  col-md-6 col-sm-5 col-12 infoinLIne">
      <mat-icon aria-hidden="false" matTooltip="Task status" class="taskIconColor" aria-label="Example home icon">task
      </mat-icon>
      <span class="iconsPadding" matTooltip="Task status"
        [ngClass]="{'taskOpen':taskStatus == 'Open', 'taskDone':taskStatus == 'Done', 'testInprogress':taskStatus == 'In Progress' }">{{taskStatus}}</span>
    </div>
    <div class="col-lg-4 col-xl-4  col-md-6 col-sm-5 col-12 infoinLIne">
      <mat-icon aria-hidden="false" matTooltip="Due date" [class.overDueColor]="isTaskOverDue == true"
        class="taskIconColor" color="primary" aria-label="Example home icon">today</mat-icon>
      <span class="iconsPadding" matTooltip="Due date"
        [class.overDueColor]="isTaskOverDue == true">{{taskDueDate}}</span>
    </div>
    <div class="col-lg-4 col-xl-4  col-md-6 col-sm-5 col-12 infoinLIne">
      <mat-icon aria-hidden="false" matTooltip="Assign user" class="taskIconColor" color="primary"
        aria-label="Example home icon">person</mat-icon>
      <span class="iconsPadding" matTooltip="Assign user">{{userName}}</span>
    </div>
  </div>
</div>

