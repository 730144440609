<app-menu></app-menu>

<div class="wrapper container-fluid p-0">
  <!-- Sidebar Holder -->
  <!-- <div class="sidebar col-md-3 col-lg-3" > -->
  <nav id="sidebar" [ngClass]="{ 'active': navbarOpen }">
    <div class="sidebar-header">
      <div class="">
        <div class="todo-search cardPadding">
          <div class="row">
            <div class="col-md-7">
              Group List
            </div>
            <div class="col-2">
              <mat-icon color="primary" class="pointer" matTooltip="Search group"
                (click)="groupSearchToggle()">search</mat-icon>
            </div>
            <div class="col-3">
              <mat-icon color="primary" class="pointer text-end" matTooltip="Add group"
                (click)="addGrouptoggle()">add_circle</mat-icon>

            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div class="card mt-2" *ngIf="(SearchGroup)">
      <div class="card-body cardPadding">
        <div class="mb-4 p-1">
          <mat-form-field>
            <i matPrefix class="fa fa-search iconColor">&nbsp;&nbsp; &nbsp;</i>
            <input matInput type="search" (keyup)="groupListSort()" placeholder="search here" [(ngModel)]="groupName"
              class="search-input">
            <div matSuffix>
              <button mat-icon-button color="warn" *ngIf="groupName.length !== 0" (click)="clearGroupName()">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="card mt-2" *ngIf="(AddGroup)">
      <div class="card-body p-3">
        <h3 class="form-sm-title text-center">Add group</h3>
        <!-- <hr /> -->
        <form [formGroup]="AddGroupForm" (ngSubmit)="onSubmitAddGroup(AddGroupForm)">
          <div class="form-group">
            <input type="text" formControlName="groupName"
              [ngClass]="{ 'is-invalid': groupSubmitted && f.groupName.errors }" placeholder="Enter group name"
              class="form-control form-control-sm">
            <mat-error *ngIf="groupSubmitted && f.groupName.errors" class="invalid-feedback">
              <mat-error *ngIf="f.groupName.errors.trimRequired">Group name is required</mat-error>
            </mat-error>
          </div>

          <div class="form-group">
            <ng-select formControlName="userEmail" bindValue="userEmail" bindLabel="userEmail" [items]="allMembersData"
              placeholder="Select Users" multiple="true" [addTag]="true" [searchable]="true" [clearable]="true"
              [(ngModel)]="selectedgroupUser">
              <ng-template ng-header-tmp>
                <div>
                  <button mat-button color="primary" (click)="onSelectAll()">Select All</button>
                  <button mat-button color="primary" (click)="onClearAll()">Clear All</button>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="text-center mb-2">
            <button type="submit" [disabled]="loadingBtn" disabled class=" btn btn-sm border btn-primary mr-1 ">
              Save
            </button>
            <button type="button" (click)="addGroupCancelButton()"
              class="btn btn-sm border btn-secondary">Cancel</button>
          </div>
        </form>
      </div>
    </div>
    <div class="card edit mt-2" *ngIf="(editForm)">
      <div class="card-body p-3">
        <h3 class="text-center">Edit group </h3>
        <!-- <hr /> -->
        <div class="form-group">
          <input type="text" placeholder="Enter group name" class="form-control form-control-sm"
            [(ngModel)]="editGroupName" />
          <mat-error class="invalid-feedback">{{errorMsg}}</mat-error>
        </div>
        <div class="text-center">
          <button type="submit" (click)="updateGroupName(editGroupName)"
            class="btn btn-sm border btn-primary mr-1">Save</button>
          <button type="button" (click)="editGroupCancel()" class="btn btn-sm border btn-secondary">Cancel</button>
        </div>
      </div>

    </div>
    <div class="groupFixedHeight scrollbar mt-2">
      <div class="row mt-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="basic-container ">
            <mat-nav-list>
              <mat-list-item *ngFor="let g of GroupData ; let i = index" class="groupList groupParent custom-border"
                (click)="getGroupdeatils(g.groupName,g.groupId,g.isGroupAdmin)"
                [class.grouprowcolor]="g.groupId == currentGroup ? g.groupId == currentGroup : g.groupId == currentGroupId">
                <div mat-line class="">
                  {{add3Dots(g.groupName,30)}}
                </div>
                <div class="fav-Group fav-menu d-flex align-items-center">
                  <div class="fav-Group">
                    <div *ngIf="g.isFavoriteGroup === true else favBlock">
                      <mat-icon class="starIcon taskIconColor1" color="primary">grade</mat-icon>
                    </div>
                    <ng-template #favBlock>
                      <div>
                        <mat-icon class="starIcon taskIconColor1" (click)="addFavGroup(g.groupId)"
                          ng-model="isFavoriteGroup" color="primary">star_border</mat-icon>
                      </div>
                    </ng-template>
                  </div>
                  <div class="groupIcons taskIconColor">
                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu" (click)="getGroupOwnerList(g.groupId)">
                      <mat-icon matTooltip="Action">more_vert</mat-icon>
                    </button>

                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                      <button mat-menu-item disabled *ngIf="g.isGroupOwner === false" (click)="deleteGroup(g.groupId)">
                        <mat-icon class="delete2" (click)="deleteGroup(g.groupId)">delete</mat-icon>
                        <span (click)="deleteGroup(g.groupId)">Delete</span>
                      </button>
                      <button mat-menu-item *ngIf="g.isGroupOwner === true" (click)="deleteGroup(g.groupId)">
                        <mat-icon class="delete2" (click)="deleteGroup(g.groupId)">delete</mat-icon>
                        <span (click)="deleteGroup(g.groupId)">Delete</span>
                      </button>
                      <button mat-menu-item *ngIf="g.isGroupOwner === true" (click)="getGroupName(g)">
                        <mat-icon class="delete2">create</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button mat-menu-item disabled *ngIf="g.isGroupOwner === false" (click)="getGroupName(g)">
                        <mat-icon class="delete2">create</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button mat-menu-item [ngClass]="{'leaveIconHide': isOwner == true}" [matMenuTriggerFor]="menu2"
                        *ngIf="g.isGroupAdmin === true && g.isGroupOwner === true else elseLeaveGroup">
                        <mat-icon class="delete2" (mouseenter)="menu2">exit_to_app</mat-icon>
                        <span>Leave group</span>
                        <mat-menu #menu2="matMenu">

                          <div (click)="cancelRemoveClick($event)" class="dropdown1"
                            *ngFor="let seting of collection.data;let i = index">
                            <div class="fixed-header">
                              <nav>
                                Group Users
                              </nav>
                            </div>
                            <div class="removeUserList">
                              <mat-list role="list" *ngFor="let o of ownerUserList">
                                <div class="row">
                                  <div class="col-lg-7 col-md-7 col-xs-7  col-sm-7 col-7">
                                    <mat-list-item role="listitem" style="padding-top: 3px;">
                                      <div>
                                        {{o.userName}} |
                                      </div>

                                    </mat-list-item>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-xs-5 col-sm-5 col-5">
                                    <div class="userDropDownList" (click)="leaveGroupOwner(g.groupId,o.groupMemberId)">
                                      <mat-card-subtitle>
                                        <small>Make admin</small>
                                      </mat-card-subtitle>
                                    </div>
                                  </div>
                                </div>
                              </mat-list>
                            </div>
                          </div>
                        </mat-menu>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </mat-list-item>

            </mat-nav-list>
          </div>
        </div>
      </div>
      <div class="row text-center" *ngIf="GroupData.length === 0">
        <div class="col-md-12">
          <p class="alert">
            <strong>
              <mat-error>No Group Found</mat-error>
            </strong>
          </p>
        </div>
      </div>
    </div>
  </nav>
  <!-- </div> -->


  <!-- Page Content Holder -->
  <div id="content">
    <div class="row taskPanel">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="">
          <div class="groupFixedHeight1">
            <div class="collapsPanel">
              <!-- <mat-icon data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                    aria-controls="collapseExample" color="default">open_with</mat-icon> -->
              <div>
                <span id="sidebarCollapse" (click)="toggleNavbar()" class="navbar-btn collpaseIconClose pt-2">
                  <mat-icon matTooltip="Close Group List">chevron_left</mat-icon>
                </span>

              </div>
              <!-- Sidebar ended // -->
              <div class="px-2">
                <ng-container>
                  <!-- //List tab start  -->
                  <mat-tab-group>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">developer_board</mat-icon>&nbsp;
                        Board
                      </ng-template>
                      <div class="row " *ngIf="isDisplayed">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 custom-filters">
                          <div class="card-body headerPanel iconPanel">
                            <!-- *ngIf="isTask" -->
                            <mat-icon color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="filterPanel" matTooltip="Filter"
                              (click)="getGroupMembersFilterList()">
                              filter_alt</mat-icon>
                            <mat-menu #filterPanel="matMenu" class="mat-menu-panel-filter">
                              <mat-tab-group (click)="stopPropagationFilterPanel($event)">
                                <mat-tab label="Search by Text">
                                  <div class="card-body cardPadding">
                                    <div class="row">
                                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="input-group border rounded-pill p-1">
                                          <div class="input-group-prepend border-0">
                                            <button id="button-addon4" type="button" class="btn btn-link text-primary">
                                              <i class="fa fa-search iconColor"></i>
                                            </button>
                                          </div>
                                          <input type="search" placeholder="Search task" [(ngModel)]="taskSearch"
                                            name="search" autocomplete="off" aria-describedby="button-addon4"
                                            class="form-control bg-none border-0">
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </mat-tab>
                                <mat-tab label="Search by Status">
                                  <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <section class="example-section  form-inline">
                                        <div *ngFor="let s of dataListpipeFilteer;let i = index">
                                          <input type="checkbox" class="example-margin " #ref [checked]="position_stack"
                                            [(ngModel)]="s.checked"
                                            (click)="getCheckBoxVAlue(s.id, dataListpipeFilteer)" />
                                          {{s.name}}
                                        </div>
                                        <button type="button" (click)="unCheckAll()"
                                          class="example-margin btn btn-xs border btn-default mr-1 track-btn-blue">
                                          Clear filter
                                        </button>

                                      </section>
                                      <pre><span *ngFor="let s of checked()" style="padding-right:10px;"></span></pre>
                                    </div>
                                  </div>
                                </mat-tab>
                                <mat-tab label="Custom Filter">
                                  <div class="card-body customFilter">
                                    <form [formGroup]="filterTaskListForm"
                                      (ngSubmit)="filterTasList(filterTaskListForm); ddTrigger.closeMenu()">
                                      <div class="row">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 custom-dropdown">
                                          <mat-form-field>
                                            <mat-select formControlName="taskStatus" placeholder="Task status" >
                                              <mat-option *ngFor="let s of this.dataListSearchFilter;let i = index"
                                                value="{{s.name}}">
                                                {{s.name}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3">
                                          <mat-form-field>
                                            <mat-select formControlName="taskAssignTo" class="userInput"
                                              placeholder="Assign user" matTooltip="Assign Users">
                                              <mat-option *ngFor="let user of groupMemberData" value="{{user.userId}}">
                                                {{user.userName}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-3 col-sm-3 col-8">
                                          <mat-form-field appearance="fill" class="dueDate">
                                            <mat-label>Due Date</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="taskDueDate"
                                              (click)="picker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3">
                                          <mat-form-field>
                                            <mat-select formControlName="taskCreator" class="userInput"
                                              placeholder="Created by" matTooltip="task created by">
                                              <mat-option *ngFor="let user of taskCreatorsData"
                                                value="{{user.userEmail}}">
                                                {{user.userName}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-8">
                                          <div class="row">
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-4 col-sm-4">
                                              <button type="submit" matTooltip="Search" color="primary" mat-button>
                                                <mat-icon>search</mat-icon>
                                              </button>
                                            </div>
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-4 col-sm-4">
                                              <button color="primary" matTooltip="Reset filter" (click)="filerClear()"
                                                mat-button>
                                                <mat-icon>restart_alt</mat-icon>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="row">
                                            <div class="col-xl-5 col-lg-5 col-md-5 col-3 col-sm-3">
                                              <button type="button" (click)="getMyTasks(); ddTrigger.closeMenu()"
                                                mat-stroked-button color="primary">My
                                                Task</button>
                                            </div>
                                            <div class="col-xl-5 col-lg-5 col-md-5 col-3 col-sm-3">
                                              <button type="button" (click)="getAlltaskList(); ddTrigger.closeMenu()"
                                                mat-stroked-button color="primary">Show All</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </mat-tab>
                              </mat-tab-group>
                            </mat-menu>

                            <mat-icon color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="tetgr"
                              matTooltip="Remove users from group"
                              (click)="getGroupMembersFilterList()">person_remove</mat-icon>

                            <mat-menu #tetgr="matMenu" [overlapTrigger]="false">
                              <div (click)="cancelRemoveClick($event)" class="dropdown1"
                                *ngFor="let seting of collection.data;let i = index">
                                <div class="fixed-header">
                                  <nav>
                                    Group members
                                  </nav>
                                </div>
                                <div class="removeUserList">
                                  <mat-list role="list" *ngFor="let gm of groupMemberData">
                                    <div class="row pt-2">
                                      <div class="col-lg-10 col-md-10 col-xs-10">
                                        <mat-list-item role="listitem" class="">
                                          <div *ngIf="gm.isGroupOwner === true; else OwnerTemp">
                                            {{gm.userName}}
                                            <mat-card-subtitle>(Owner) </mat-card-subtitle>
                                          </div>
                                          <ng-template #OwnerTemp>
                                            {{gm.userName}}</ng-template>
                                        </mat-list-item>
                                      </div>
                                      <div class="col-lg-2 col-md-2 col-xs-2">
                                        <div *ngIf="gm.isGroupOwner === true; else deleteBlock">
                                          <button class="delete" [hidden]="gm.isGroupOwner === true"
                                            (click)="fireDeleteEventUser(gm.groupMemberId)">
                                            <!-- Adding Mat Icon via svg due to select bug -->
                                            <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0 0h24v24H0V0z" fill="none" />
                                              <path
                                                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                              <path d="M0 0h24v24H0z" fill="none" />
                                            </svg>
                                          </button>
                                        </div>
                                        <ng-template #deleteBlock>
                                          <div *ngIf="isGroupAdmin === false; else deletetempBlock">
                                            <button class="delete deleteBtn"
                                              (click)="fireDeleteEventUser(gm.groupMemberId)">
                                              <!-- Adding Mat Icon via svg due to select bug -->
                                              <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                                <path d="M0 0h24v24H0z" fill="none" />
                                              </svg>
                                            </button>
                                          </div>
                                          <ng-template #deletetempBlock>
                                            <button class="delete " (click)="fireDeleteEventUser(gm.groupMemberId)">
                                              <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                                <path d="M0 0h24v24H0z" fill="none" />
                                              </svg>
                                            </button>
                                          </ng-template>
                                        </ng-template>
                                      </div>
                                    </div>
                                  </mat-list>
                                </div>
                              </div>
                            </mat-menu>

                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              matTooltip="Add/Invite Users" [matMenuTriggerFor]="inviteMenu"
                              (click)="getNewGroupMembrsList()">
                              person_add
                            </mat-icon>
                            <mat-menu #inviteMenu="matMenu">
                              <div class="dropdown1">
                                <div class="card">
                                  <div class="fixed-header-invite-user">
                                    <nav>
                                      Add/Invite Users
                                    </nav>
                                  </div>
                                  <div class="card-body invitePanel" (click)="stopPropagationAddmember($event)">
                                    <form [formGroup]="addGroupNewMembersForm"
                                      (ngSubmit)="addGroupMembers(addGroupNewMembersForm)">
                                      <div class="form-group">
                                        <ng-select [ngClass]="{ 'is-invalid': submitted && u.userEmail.errors }"
                                          [items]="allMembersData" formControlName="userEmail" bindValue="userEmail"
                                          bindLabel="userEmail" placeholder="Select/Add Users" multiple="true"
                                          [addTag]="true" [searchable]="true" [clearable]="true">
                                          <ng-template ng-header-tmp>
                                            <div>
                                              <button mat-button color="primary" (click)="onSelectAllInvite()">Select
                                                All</button>
                                              <button mat-button color="primary" (click)="onClearAllInvite()">Clear
                                                All</button>
                                            </div>
                                          </ng-template>
                                        </ng-select>

                                        <!-- rudresh work start-->

                                        <!-- rudresh work end -->
                                      </div>
                                      <div class="text-center mb-2">
                                        <button type="submit" [disabled]="loadingBtn" disabled
                                          class=" btn btn-xs border btn-default mr-1 track-btn-blue">
                                          Save
                                        </button>
                                        <button type="button" (click)="addGroupCancel()"
                                          class="btn btn-xs border btn-default">clear</button>
                                      </div>

                                    </form>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              (click)="onReminder()" matTooltip="Task reminder to group members">
                              contact_mail
                            </mat-icon>
                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="dd" matTooltip="Application settings ">
                              settings_applications</mat-icon>
                            <mat-menu #dd="matMenu" [overlapTrigger]="false">
                              <div (click)="cancelClick($event)" class="dropdownSystem" style="padding: 13px;"
                                *ngFor="let seting of collection.data;let i = index">
                                <div style="text-transform: capitalize;">{{seting.emailpreferences}}</div>
                                <mat-slide-toggle color="primary"
                                  *ngFor="let s of seting.systemSettingsData;let i = index" [checked]="isChecked"
                                  #toggleElement [(ngModel)]="isChecked" (change)="onChange($event.checked,seting)">
                                  Send Email to Assigned Users
                                </mat-slide-toggle>
                              </div>
                            </mat-menu>
                          </div>
                        </div>
                      </div>
                      <div class="row div-inline-m">
                        <div class="col-md-4">
                          <div class="drag-container">
                            <div class="section-headingOpen">Open <span
                                class="widgetCount">{{OpenTaskList.length}}</span></div>
                            <div cdkDropList id="openList" #openList="cdkDropList" [cdkDropListData]="OpenTaskList"
                              [cdkDropListConnectedTo]="[progresList,doneList]" class="item-list"
                              (cdkDropListDropped)="drop($event)">
                              <div class="item-box"
                                *ngFor="let t of OpenTaskList | filter: taskSearch | taskByStatus: checked() : false | slice:0:openShowMoreTaskList; let i=index"
                                cdkDrag (cdkDragStarted)="draggingStarted = false"
                                (cdkDragEnded)="draggingStarted = true"
                                [cdkDragDisabled]="t.taskAssignTo !== null ? null : true">
                                <pre (click)="editTaskDialog(t)" appCustomManipulationText
                                  [innerHTML]="t.taskDescription | noSanitize" class="desc-space"></pre>
                                <div class="row align-items-center upper-line">
                                  <div class="col-3">
                                    <mat-icon #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="pp"
                                      (click)="taskPriorityValue(t)"
                                      [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }"
                                      aria-hidden="false" matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}"
                                      class="taskIconColor" color="primary"
                                      aria-label="Example home icon">flag</mat-icon>
                                    <mat-menu #pp="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                                        <mat-form-field class="mat-sizes">
                                          <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                                            (selectionChange)="getTaskPriorityValue($event,t)">
                                            <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                                              {{pp.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                  </div>
                                  <div class="col-3">
                                    <div class="center-vertically" *ngIf="t.userDetails.userName else showUserMsg">
                                      <span class="userIcon" matTooltipClass='user-tooltip'
                                        matTooltip="{{tooltipUserNameOpen}}" (mouseenter)="getOpenUserNameTooltip(t)"
                                        matTooltip="Assign user" #trigger="matMenuTrigger" [matMenuTriggerFor]="aa"
                                        (click)="taskUserChange(t)">
                                        {{t.userDetails.userName | shortName}}</span>
                                    </div>
                                    <mat-menu #aa="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                                        <mat-form-field class="mat-sizes">
                                          <mat-label> {{isMaker? "Select an option" : "Selected User"}}</mat-label>
                                          <mat-select [(value)]="selectedUser ? selectedUser : noAssignee"
                                            (selectionChange)="taskUserChangeByUser($event, t.taskId, t.taskDescription,t.taskStatus,t.taskDueDate)">
                                            <input class="input-pad" matInput placeholder="Select User"
                                              (keyup)="onKey($event.target.value)">
                                            <mat-option *ngFor="let item of groupMemberData" [value]="item.userId">
                                              {{item.userName}}
                                            </mat-option>
                                            <mat-option value="noAssignee">
                                              No Assignee
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                    <ng-template #showUserMsg>
                                      <div class="center-vertically">
                                        <mat-icon class="delete2 defaultIconsClr" (click)="taskUserChange(t)"
                                          #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="aa" matTooltip="Assign user">
                                          person</mat-icon>
                                      </div>
                                    </ng-template>

                                  </div>
                                  <div class="col-3">
                                    <!-- // due Date -->
                                    <mat-datepicker #picker3></mat-datepicker>
                                    <input readonly matTooltipClass='red-tooltip' matTooltip="{{tooltipDueDateOpen }}"
                                      (mouseenter)="getOpenDueDateTooltip(t)" *ngIf="t.taskDueDate else showDeuDate"
                                      (dateInput)="taskUserupdateByDueDate('input', $event,t)"
                                      value="{{t.taskDueDate | date: 'MMM dd'}}" type="text" (click)="picker3.open()"
                                      placeholder="{{t.taskDueDate | date: 'MMM dd'}}" matInput
                                      [matDatepicker]="picker3" class=" pointer"
                                      [class.overDueColor]="t.isTaskOverDue === true" />
                                    <ng-template #showDeuDate>
                                      <div class="center-vertically1 delete2">
                                        <mat-icon (click)="picker3.open()" class="delete2 taskIconColor"
                                          matTooltip="Due Date">event</mat-icon>
                                        <mat-datepicker #picker3></mat-datepicker>
                                        <span>
                                          <input matTooltip="Due Date" readonly
                                            (dateInput)="taskUserupdateByDueDate('input', $event,t)" [min]="TodayDate"
                                            type="text" (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                            class="dueDateInput delete2" />
                                        </span>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-3">
                                    <button mat-icon-button [matMenuTriggerFor]="beforeTaskMenu"
                                      aria-label="Example icon-button with a menu">
                                      <mat-icon matTooltip="Action">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #beforeTaskMenu="matMenu" xPosition="before">
                                      <button mat-menu-item (click)="getTaskDetailsById(t.taskId)">
                                        <mat-icon class="delete2">visibility</mat-icon>
                                        <span>View</span>
                                      </button>
                                      <button mat-menu-item (click)="deleteTask(t.taskId)" *ngIf="t.isTaskOwner">
                                        <mat-icon class="delete2">delete</mat-icon>
                                        <span>Delete</span>
                                      </button>
                                    </mat-menu>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center  mt-2" *ngIf="showInOpen">
                                <button type="button" *ngIf="openShowMoreTaskList < OpenTaskList.length"
                                  class="btn btn-xs border btn-default mr-1 mb-2 btn-sm track-btn-blue"
                                  (click)="openShowMoreTask()">Show More+
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="col-md-4">
                          <div class="drag-container">
                            <div class="section-headingInprogres">In Progress <span
                                class="widgetCount">{{InProgressTaskList.length}}</span></div>
                            <div cdkDropList id="progresList" #progresList="cdkDropList"
                              [cdkDropListData]="InProgressTaskList" [cdkDropListConnectedTo]="[openList,doneList]"
                              class="item-list" (cdkDropListDropped)="drop($event)">
                              <div class="item-box"
                                *ngFor="let t of InProgressTaskList | filter: taskSearch | taskByStatus: checked() : false | slice:0:inProgressShowMoreTaskList; let i=index"
                                cdkDrag (cdkDragStarted)="draggingStarted = false"
                                (cdkDragEnded)="draggingStarted = true">
                                <pre (click)="editTaskDialog(t)" appCustomManipulationText
                                  [innerHTML]="t.taskDescription | noSanitize" class="desc-space"></pre>

                                <div class="row align-items-center upper-line">
                                  <div class="col-3">
                                    <mat-icon #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="pp"
                                      (click)="taskPriorityValue(t)"
                                      [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }"
                                      aria-hidden="false" matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}"
                                      class="taskIconColor" color="primary"
                                      aria-label="Example home icon">flag</mat-icon>
                                    <mat-menu #pp="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">

                                        <mat-form-field class="mat-sizes">
                                          <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                                            (selectionChange)="getTaskPriorityValue($event,t)">
                                            <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                                              {{pp.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                  </div>
                                  <div class="col-3">
                                    <div class="center-vertically" *ngIf="t.userDetails.userName else showUserMsg">
                                      <span class="userIcon" matTooltipClass='user-tooltip'
                                        matTooltip="{{tooltipUserNameInprogress}}"
                                        (mouseenter)="getInprogressUserNameTooltip(t)" matTooltip="Assign user"
                                        #trigger="matMenuTrigger" [matMenuTriggerFor]="aa" (click)="taskUserChange(t)">
                                        {{t.userDetails.userName | shortName}}</span>
                                    </div>
                                    <mat-menu #aa="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                                        <mat-form-field class="mat-sizes">
                                          <mat-label> {{isMaker ? "Select an option" : "Selected User"}}</mat-label>
                                          <mat-select [(value)]="selectedUser ? selectedUser : noAssignee"
                                            (selectionChange)="taskUserChangeByUser($event, t.taskId, t.taskDescription,t.taskStatus,t.taskDueDate)">
                                            <input class="input-pad" matInput placeholder="Select User"
                                              (keyup)="onKey($event.target.value)">
                                            <mat-option *ngFor="let item of groupMemberData" [value]="item.userId">
                                              {{item.userName}}
                                            </mat-option>
                                            <mat-option value="noAssignee">
                                              No Assignee
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                    <ng-template #showUserMsg>
                                      <div class="center-vertically">
                                        <mat-icon class="delete2 defaultIconsClr" (click)="taskUserChange(t)"
                                          #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="aa" matTooltip="Assign user">
                                          person</mat-icon>
                                      </div>
                                    </ng-template>

                                  </div>
                                  <div class="col-3">
                                    <!-- // due Date -->
                                    <mat-datepicker #picker3></mat-datepicker>
                                    <input readonly matTooltipClass='red-tooltip'
                                      matTooltip="{{tooltipDueDateInprogress }}"
                                      (mouseenter)="getInProgressDueDateTooltip(t)"
                                      *ngIf="t.taskDueDate else showDeuDate"
                                      (dateInput)="taskUserupdateByDueDate('input', $event,t)"
                                      value="{{t.taskDueDate | date: 'MMM dd'}}" type="text" (click)="picker3.open()"
                                      placeholder="{{t.taskDueDate | date: 'MMM dd'}}" matInput
                                      [matDatepicker]="picker3" class="pointer"
                                      [class.overDueColor]="t.isTaskOverDue === true" />
                                    <ng-template #showDeuDate>
                                      <div class="center-vertically1 delete2">
                                        <mat-icon (click)="picker3.open()" class="delete2 taskIconColor"
                                          matTooltip="Due Date">event</mat-icon>
                                        <mat-datepicker #picker3></mat-datepicker>
                                        <span>
                                          <input matTooltip="Due Date" readonly
                                            (dateInput)="taskUserupdateByDueDate('input', $event,t)" [min]="TodayDate"
                                            type="text" (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                            class="dueDateInput delete2" />
                                        </span>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-3">
                                    <button mat-icon-button [matMenuTriggerFor]="beforeTaskMenu"
                                      aria-label="Example icon-button with a menu">
                                      <mat-icon matTooltip="Action">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #beforeTaskMenu="matMenu" xPosition="before">
                                      <button mat-menu-item (click)="getTaskDetailsById(t.taskId)">
                                        <mat-icon class="delete2">visibility</mat-icon>
                                        <span>View</span>
                                      </button>
                                      <button mat-menu-item (click)="deleteTask(t.taskId)" *ngIf="t.isTaskOwner">
                                        <mat-icon class="delete2">delete</mat-icon>
                                        <span>Delete</span>
                                      </button>
                                    </mat-menu>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center  mt-2" *ngIf="showInProgress">
                                <button type="button" *ngIf="inProgressShowMoreTaskList < InProgressTaskList.length"
                                  class="btn btn-xs border btn-default mr-1 mb-2 btn-sm track-btn-blue"
                                  (click)="inProgressShowMoreTask()">Show More+
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="drag-container">
                            <div class="section-headingDone">Done <span
                                class="widgetCount">{{DoneTaskList.length}}</span> </div>
                            <div cdkDropList id="doneList" #doneList="cdkDropList" [cdkDropListData]="DoneTaskList"
                              [cdkDropListConnectedTo]="[progresList,openList]" class="item-list"
                              (cdkDropListDropped)="drop($event)">
                              <div class="item-box"
                                *ngFor="let t of DoneTaskList   | filter: taskSearch | taskByStatus: checked() : false | slice:0:doneShowMoreTaskList; let i=index"
                                cdkDrag (cdkDragStarted)="draggingStarted = false"
                                (cdkDragEnded)="draggingStarted = true">
                                <pre (click)="editTaskDialog(t)" class="desc-space" appCustomManipulationText
                                  [innerHTML]="t.taskDescription | noSanitize"></pre>

                                <div class="row align-items-center upper-line">
                                  <div class="col-3">
                                    <mat-icon (click)="taskPriorityValue(t)" #ddTrigger="matMenuTrigger"
                                      [matMenuTriggerFor]="pp"
                                      [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }"
                                      aria-hidden="false" matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}"
                                      class="taskIconColor" color="primary"
                                      aria-label="Example home icon">flag</mat-icon>
                                    <mat-menu #pp="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                                        <mat-form-field class="mat-sizes">
                                          <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                                            (selectionChange)="getTaskPriorityValue($event,t)">
                                            <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                                              {{pp.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                  </div>
                                  <div class="col-3">
                                    <div class="center-vertically" *ngIf="t.userDetails.userName else showUserMsg">
                                      <span class="userIcon" matTooltipClass='user-tooltip'
                                        matTooltip="{{tooltipUserNameDone}}" (mouseenter)="getDoneUserNameTooltip(t)"
                                        matTooltip="Assign user" #trigger="matMenuTrigger" [matMenuTriggerFor]="aa"
                                        (click)="taskUserChange(t)">
                                        {{t.userDetails.userName | shortName}}</span>
                                    </div>
                                    <mat-menu #aa="matMenu" [overlapTrigger]="false">
                                      <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                                        <mat-form-field class="mat-sizes">
                                          <mat-label> {{isMaker ? "Select an option" : "Selected User"}}</mat-label>
                                          <mat-select [(value)]="selectedUser ? selectedUser : noAssignee"
                                            (selectionChange)="taskUserChangeByUser($event, t.taskId, t.taskDescription,t.taskStatus,t.taskDueDate)">
                                            <input class="input-pad" matInput placeholder="Select User"
                                              (keyup)="onKey($event.target.value)">
                                            <mat-option *ngFor="let item of groupMemberData" [value]="item.userId">
                                              {{item.userName}}
                                            </mat-option>
                                            <mat-option value="noAssignee">
                                              No Assignee
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </mat-menu>
                                    <ng-template #showUserMsg>
                                      <div class="center-vertically">
                                        <mat-icon class="delete2 defaultIconsClr" (click)="taskUserChange(t)"
                                          #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="aa" matTooltip="Assign user">
                                          person</mat-icon>
                                      </div>
                                    </ng-template>

                                  </div>
                                  <div class="col-3">
                                    <!-- // due Date -->
                                    <mat-datepicker #picker3></mat-datepicker>
                                    <input *ngIf="t.taskDueDate else showDeuDate" readonly
                                      [class.overDueColor]="t.isTaskOverDue === true" matTooltipClass='red-tooltip'
                                      matTooltip="{{tooltipDueDateDone}}" (mouseenter)="getDoneDueDateTooltip(t)"
                                      (dateInput)="taskUserupdateByDueDate('input', $event,t)"
                                      value="{{t.taskDueDate | date: 'MMM dd'}}" type="text" (click)="picker3.open()"
                                      placeholder="{{t.taskDueDate | date: 'MMM dd'}}" matInput
                                      [matDatepicker]="picker3" class=" pointer"
                                      [class.overDueColor]="t.isTaskOverDue === true" />
                                    <ng-template #showDeuDate>
                                      <div class="center-vertically1 delete2">
                                        <mat-icon (click)="picker3.open()" class="delete2 taskIconColor"
                                          matTooltip="Due Date">event</mat-icon>
                                        <mat-datepicker #picker3></mat-datepicker>
                                        <span>
                                          <input matTooltip="Due Date" readonly
                                            (dateInput)="taskUserupdateByDueDate('input', $event,t)" [min]="TodayDate"
                                            type="text" (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                            class="dueDateInput delete2" />
                                        </span>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-3">
                                    <button mat-icon-button [matMenuTriggerFor]="beforeTaskMenu"
                                      aria-label="Example icon-button with a menu">
                                      <mat-icon matTooltip="Action">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #beforeTaskMenu="matMenu" xPosition="before">
                                      <button mat-menu-item (click)="getTaskDetailsById(t.taskId)">
                                        <mat-icon class="delete2">visibility</mat-icon>
                                        <span>View</span>
                                      </button>
                                      <button mat-menu-item (click)="deleteTask(t.taskId)" *ngIf="t.isTaskOwner">
                                        <mat-icon class="delete2">delete</mat-icon>
                                        <span>Delete</span>
                                      </button>
                                      <button mat-menu-item (click)="ClosedTask(t.taskId)">
                                        <mat-icon class="delete2">done</mat-icon>
                                        <span>Close task</span>
                                      </button>
                                    </mat-menu>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center  mt-2" *ngIf="showInDone">
                                <button type="button" *ngIf="doneShowMoreTaskList < DoneTaskList.length"
                                  class="btn btn-xs border btn-default mr-1 mb-2 btn-sm track-btn-blue"
                                  (click)="doneShowMoreTask()">Show More+
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <!-- //borad tab end  -->
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">list</mat-icon>&nbsp;
                        List
                      </ng-template>
                      <div class="row " *ngIf="isDisplayed">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                          <div class="card-body headerPanel iconPanel custom-filters">
                            <mat-icon color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="filterPanel" matTooltip="Filter"
                              (click)="getGroupMembersFilterList()">
                              filter_alt</mat-icon>
                            <mat-menu #filterPanel="matMenu" class="mat-menu-panel-filter">
                              <mat-tab-group (click)="stopPropagationFilterPanel($event)">
                                <mat-tab label="Search by Text">
                                  <div class="card-body cardPadding">
                                    <div class="row">
                                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="input-group border rounded-pill p-1">
                                          <div class="input-group-prepend border-0">
                                            <button id="button-addon4" type="button" class="btn btn-link text-primary">
                                              <i class="fa fa-search iconColor"></i>
                                            </button>
                                          </div>
                                          <input type="search" placeholder="Search task" [(ngModel)]="taskSearch"
                                            name="search" autocomplete="off" aria-describedby="button-addon4"
                                            class="form-control bg-none border-0">
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </mat-tab>
                                <mat-tab label="Search by Status">
                                  <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <section class="example-section  form-inline ml-5 pl-2">
                                        <div *ngFor="let s of dataListpipeFilteer;let i = index">
                                          <input type="checkbox" class="example-margin " #ref [checked]="s.checked"
                                            [(ngModel)]="s.checked"
                                            (click)="getCheckBoxVAlue(s.id, dataListpipeFilteer)" />
                                          {{s.name}}
                                        </div>
                                        <button type="button" class=" ml-3 " (click)="unCheckAll()" mat-stroked-button
                                          color="primary"> Clear filter</button>

                                      </section>
                                      <pre><span *ngFor="let s of checked()" style="padding-right:10px;"></span></pre>
                                    </div>
                                  </div>
                                </mat-tab>
                                <mat-tab label="Custom Filter">
                                  <div class="card-body customFilter">
                                    <form [formGroup]="filterTaskListForm"
                                      (ngSubmit)="filterTasList(filterTaskListForm)">
                                      <div class="row taskStatus_dropdown">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3 ">
                                          <mat-form-field>
                                            <mat-select formControlName="taskStatus" placeholder="Task status">
                                              <mat-option *ngFor="let s of this.dataListSearchFilter;let i = index"
                                                value="{{s.name}}">
                                                {{s.name}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3 ">
                                          <mat-form-field>
                                            <mat-select formControlName="taskAssignTo" class="userInput"
                                              placeholder="Assign user" matTooltip="Assign Users">
                                              <mat-option *ngFor="let user of groupMemberData" value="{{user.userId}}">
                                                {{user.userName}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3">
                                          <mat-form-field appearance="fill" class="dueDate">
                                            <mat-label>Due Date</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="taskDueDate"
                                              (click)="picker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-6 col-sm-3">
                                          <mat-form-field>
                                            <mat-select formControlName="taskCreator" class="userInput"
                                              placeholder="Created by" matTooltip="task created by">
                                              <mat-option *ngFor="let user of taskCreatorsData"
                                                value="{{user.userEmail}}">
                                                {{user.userName}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-8">
                                          <div class="row">
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-4 col-sm-4">
                                              <button type="submit" class="filterIcons" matTooltip="Search"
                                                color="primary" mat-button>
                                                <mat-icon>search</mat-icon>
                                              </button>
                                            </div>
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-4 col-sm-4">
                                              <button color="primary" class="filterIcons" matTooltip="Reset filter"
                                                (click)="filerClear()" mat-button>
                                                <mat-icon>restart_alt</mat-icon>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="row">
                                            <div class="col-xl-5 col-lg-5 col-md-5 col-3 col-sm-3">
                                              <button type="button" (click)="getMyTasks()" mat-stroked-button
                                                color="primary">My
                                                Task</button>
                                            </div>
                                            <div class="col-xl-5 col-lg-5 col-md-5 col-3 col-sm-3">
                                              <button type="button" (click)="getAlltaskList()" mat-stroked-button
                                                color="primary">Show All</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </mat-tab>

                              </mat-tab-group>
                            </mat-menu>

                            <mat-icon color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="tetgr"
                              matTooltip="Remove users from group" (click)="getGroupMembersFilterList()">person_remove
                            </mat-icon>

                            <mat-menu #tetgr="matMenu" [overlapTrigger]="false">
                              <div (click)="cancelRemoveClick($event)" class="dropdown1"
                                *ngFor="let seting of collection.data;let i = index">
                                <div class="fixed-header">
                                  <nav>
                                    Group members
                                  </nav>
                                </div>
                                <div class="removeUserList">
                                  <mat-list role="list" *ngFor="let gm of groupMemberData">
                                    <div class="row">
                                      <div class="col-lg-10 col-md-10 col-xs-10">
                                        <mat-list-item role="listitem" class="">
                                          <div *ngIf="gm.isGroupOwner === true; else OwnerTemp">
                                            {{gm.userName}}
                                            <mat-card-subtitle>(Owner) </mat-card-subtitle>
                                          </div>
                                          <ng-template #OwnerTemp>
                                            {{gm.userName}}</ng-template>
                                        </mat-list-item>
                                      </div>
                                      <div class="col-lg-2 col-md-2 col-xs-2">
                                        <div *ngIf="gm.isGroupOwner === true; else deleteBlock">
                                          <button class="delete" [hidden]="gm.isGroupOwner === true"
                                            (click)="fireDeleteEventUser(gm.groupMemberId)">
                                            <!-- Adding Mat Icon via svg due to select bug -->
                                            <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0 0h24v24H0V0z" fill="none" />
                                              <path
                                                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                              <path d="M0 0h24v24H0z" fill="none" />
                                            </svg>
                                          </button>
                                        </div>
                                        <ng-template #deleteBlock>
                                          <div *ngIf="isGroupAdmin === false; else deletetempBlock">
                                            <button class="delete deleteBtn"
                                              (click)="fireDeleteEventUser(gm.groupMemberId)">
                                              <!-- Adding Mat Icon via svg due to select bug -->
                                              <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                                <path d="M0 0h24v24H0z" fill="none" />
                                              </svg>
                                            </button>
                                          </div>
                                          <ng-template #deletetempBlock>
                                            <button class="delete " (click)="fireDeleteEventUser(gm.groupMemberId)">
                                              <svg fill="#cccccc" height="24" viewBox="0 0 24 24" width="24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                                <path d="M0 0h24v24H0z" fill="none" />
                                              </svg>
                                            </button>
                                          </ng-template>
                                        </ng-template>
                                      </div>
                                    </div>
                                  </mat-list>
                                </div>
                              </div>
                            </mat-menu>

                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              matTooltip="Add/Invite Users" [matMenuTriggerFor]="inviteMenu"
                              (click)="getNewGroupMembrsList()">
                              person_add
                            </mat-icon>
                            <mat-menu #inviteMenu="matMenu">
                              <div class="dropdown1">
                                <div class="card">
                                  <div class="fixed-header-invite-user">
                                    <nav>
                                      Add/Invite Users....
                                    </nav>
                                  </div>
                                  <div class="card-body invitePanel" (click)="stopPropagationAddmember($event)">
                                    <form [formGroup]="addGroupNewMembersForm"
                                      (ngSubmit)="addGroupMembers(addGroupNewMembersForm)">
                                      <div class="form-group">
                                        <ng-select [ngClass]="{ 'is-invalid': submitted && u.userEmail.errors }"
                                          [items]="allMembersData" formControlName="userEmail" bindValue="userEmail"
                                          bindLabel="userEmail" placeholder="Select Users and add Users" multiple="true"
                                          [addTag]="true" [searchable]="true" [clearable]="true">
                                          <ng-template ng-header-tmp>
                                            <div>
                                              <button mat-button color="primary" (click)="onSelectAllInvite()">Select
                                                All</button>
                                              <button mat-button color="primary" (click)="onClearAllInvite()">Clear
                                                All</button>
                                            </div>
                                          </ng-template>
                                        </ng-select>
                                      </div>
                                      <div class="text-center mb-2">
                                        <button type="submit" [disabled]="loadingBtn" disabled
                                          class=" btn btn-xs border btn-default mr-1 track-btn-blue">
                                          Save
                                        </button>
                                      </div>

                                    </form>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              (click)="onReminder()" matTooltip="Task reminder to group members">
                              contact_mail
                            </mat-icon>
                            <mat-icon *ngIf="(systemSettingIcon)" color="primary"
                              class="pt-2 pointer taskIconColor mat-primary mx-2 bd-highlight d-inline-block"
                              #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="dd" matTooltip="Application settings ">
                              settings_applications</mat-icon>
                            <mat-menu #dd="matMenu" [overlapTrigger]="false">
                              <div (click)="cancelClick($event)" class="dropdownSystem" style="padding: 13px;"
                                *ngFor="let seting of collection.data;let i = index">
                                <div style="text-transform: capitalize;">{{seting.emailpreferences}}</div>
                                <mat-slide-toggle color="primary"
                                  *ngFor="let s of seting.systemSettingsData;let i = index" [checked]="isChecked"
                                  #toggleElement [(ngModel)]="isChecked" (change)="onChange($event.checked,seting)">
                                  Send Email to Assigned Users
                                </mat-slide-toggle>
                              </div>
                            </mat-menu>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="isDisplayed">
                        <div class="col-12 col-md-12">
                          <!-- //ui task list -->
                          <div class="itemList">
                            <app-task-list (sendTaskListItem)='getItemGroupTaskList($event)'
                              *ngFor="let t of groupTaskList |   filter: taskSearch | taskByStatus: checked() : false | slice:0:showMoreTaskList; let i=index"
                              [t]="t"></app-task-list>
                          </div>
                          <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 text-center">
                            <button type="button" *ngIf="showMoreTaskList < groupTaskList.length"
                              class="text-center btn btn-xs border btn-default mr-1 mb-2 btn-sm track-btn-blue"
                              (click)="showMoreTask()">Show More Task+
                            </button>
                          </div>
                          <!-- //ui task list end-->
                        </div>
                      </div>
                    </mat-tab>
                    <!-- //List tab end  -->

                    <!--  closed task list tab start -->
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">list</mat-icon>&nbsp;
                        Closed Tasks
                      </ng-template>
                      <div class="itemList">
                        <div class="row justify-content-md-end mb-4" *ngIf="isDisplayed">
                          <div class="col-4 pt-1 ">
                            <input type="search" [(ngModel)]="searchTaskTerm" placeholder="Search tasks..."
                              aria-describedby="button-addon4" class="form-control bg-none " />
                          </div>
                        </div>
                        <app-closed-task-list (sendTaskList)='getClosedTaskList($event)'
                          *ngFor="let t of closedTaskList |   filter: searchTaskTerm ; let i=index"
                          [t]="t"></app-closed-task-list>
                      </div>
                    </mat-tab>
                    <!-- closed task lsit tab end  -->

                  </mat-tab-group>

                </ng-container>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>
<div class="addBtn" (click)="addTask()">
  <mat-icon color="default">add</mat-icon>Task
</div>
<div class="textAddBtn" (click)="openTextCopy()">
  <mat-icon matTooltip="Add text copy todo task" color="default">post_add</mat-icon>
</div>
