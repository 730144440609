<div class="signin-content">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-heading header-text">
                <img src="assets/img/Logo.png" alt="Track2Ecxel" />
            </div>
            <hr class="header-border">
            <div class="modal-body">
                <div class="text-center">
                    <h3>Forget Password </h3>
                </div>
                <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-addon">
                                <span class="glyphicon glyphicon-user"></span>
                            </span>
                            <input type="text" class="form-control" placeholder="Enter email id"
                                formControlName="userEmail" required
                                [ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }" />
                            <mat-error *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                                <mat-error *ngIf="f.userEmail.errors.required">Email is required</mat-error>
                                <mat-error *ngIf="f.userEmail.errors.pattern">Email must be a valid email address
                                </mat-error>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="box bg-1">
                            <button type="submit" [disabled]="loadingBtn"
                                class="btn btn-xs border btn-default mr-1 track-btn-blue btn-md">Send</button>
                        </div>
                        <a href="javascript:void(0)" routerLink="/login" class="btn btn-link">Back to login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>