import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { TodoTaskService } from '../../_services/todo-task.service';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../../_services/alert.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { LoaderService } from '../../_services/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-todo-dialog',
  templateUrl: './todo-dialog.component.html',
  styleUrls: ['./todo-dialog.component.css'],
})
export class TodoDialogComponent implements OnInit {
  taskId: string;
  taskDetails: any;
  userName: any;
  userEmail: any;
  taskDescription: any;
  taskDueDate: any;
  taskStatus: any;
  TaskId: any;
  taskDueDateFormat: any;
  taskClassDescription: boolean = false;
  isTaskOverDue = false;
  createdAt: any;
  createdBy: any;
  askListCommet: any = [];
  addCommentForm: FormGroup;
  TaskDetails: any;
  submitted = false;
  loadingBtn = false;
  taskAssignTo: any;
  taskPriority: string;
  tooltipUserNameOpen: any;
  groupName: string;
  mentionConfig: any;
  items: any[] = [];
  groupMemberData: any = [];
  groupSelected: string;
  taskText: string;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private loader: LoaderService,
    public datepipe: DatePipe,
    private todoTaskService: TodoTaskService,
    private toastr: ToastrService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<TodoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.taskDetails = data.pageValue;
    this.taskDueDateFormat = this.datePipe.transform(
      this.taskDetails.taskDueDate
    );
    this.userName = this.taskDetails.userDetails.userName
      ? this.taskDetails.userDetails.userName
      : 'No assignee';
    this.userEmail = this.taskDetails.userDetails.userEmail;
    this.taskDescription = this.taskDetails.taskDescription;
    this.taskDueDate = this.taskDueDateFormat
      ? this.taskDueDateFormat
      : 'No due date';
    this.taskStatus = this.taskDetails.taskStatus;
    this.TaskId = this.taskDetails.taskId;
    this.isTaskOverDue = this.taskDetails.isTaskOverDue;
    this.createdAt = this.taskDetails.createdAt;
    this.createdBy = this.taskDetails.createdUserDetails.userName;
    this.taskAssignTo = this.taskDetails.taskAssignTo;
    this.taskPriority = this.taskDetails.taskPriority;

    this.mentionConfig = {
      mentions: [
        {
          items: this.items,
          labelKey: 'tag',
          triggerChar: '@',
          disableSort: true,
          mentionSelect: this.itemMentioned,
        },
      ],
    };
  }

  itemMentioned(tag) {
    if (tag.display == '@e ') {
    }
    return tag.display;
  }

  eventHandler(event) {
    if (event.keyCode == 64) {
      this.groupSelected = localStorage.getItem('groupId');
      this.getGroupMembers(this.groupSelected);
      this.mentionConfig = {
        mentions: [
          {
            items: this.items,
            labelKey: 'tag',
            disableSort: true,
            triggerChar: '@',
            mentionSelect: this.itemMentioned,
          },
        ],
      };
    }
  }

  getGroupMembers(groupId) {
    if (groupId == null || groupId == undefined || groupId == '') {
      return;
    } else {
      this.todoTaskService.getGroupMembers(groupId).subscribe(
        (res) => {
          this.groupMemberData = res.groupMembers;
          var textUser = new Array();
          for (let i = 0; i < this.groupMemberData.length; i++) {
            textUser.push({
              tag: '@' + this.groupMemberData[i].userName,
              display: '@' + this.groupMemberData[i].userName,
            });
          }
          this.items = textUser;
        },
        (error) => {}
      );
    }
  }
  ngOnInit() {
    this.groupName = localStorage.getItem('groupName');
    this.addCommentForm = this.fb.group({
      taskComment: ['', [Validators.required]],
    });

    this.getTaskDetailsbyId(this.TaskId);
  }

  getTaskDetailsbyId(TaskId) {
    if (TaskId == null) {
      return;
    }
    this.todoTaskService.getGroupTaskCommentList(TaskId).subscribe(
      (res) => {
        this.TaskDetails = res.taskCommentList.reverse();
      },
      (error) => {
        if (error === 'Token Expired') {
          this.router.navigate(['/']);
        }
      }
    );
  }

  get a() {
    return this.addCommentForm.controls;
  }

  saveComment() {
    this.submitted = true;
    this.loadingBtn = true;
    let getGroupId = localStorage.getItem('groupId');
    let createCommentData = {
      groupId: getGroupId,
      taskAssignTo: this.taskAssignTo,
      taskId: this.TaskId,
      taskComment: this.addCommentForm.value.taskComment,
    };
    this.loading = true;
    this.todoTaskService.addComment(createCommentData).subscribe(
      (res) => {
        if (res.status === false) {
          this.loadingBtn = false;
          this.loading = false;
        } else {
          if (res.status === 'success') {
            this.toastr.success('Success', res.message);
            this.loading = false;
            this.addCommentForm.reset();
            this.getTaskDetailsbyId(this.TaskId);
            this.loadingBtn = false;
            this.submitted = false;
          }
        }
      },
      (error) => {
        this.loading = false;
        this.loadingBtn = false;
        this.toastr.error('Error', error);
      }
    );
  }

  updateComment() {
    // commentId
  }

  deleteComment(commentId) {
    this.todoTaskService.deleteComment(commentId).subscribe(
      (res) => {
        if (res.status === false) {
        } else {
          if (res.status === 'success') {
            this.toastr.success('Success', res.message);
            this.getTaskDetailsbyId(this.TaskId);
          }
        }
      },
      (error) => {
        this.toastr.error('Error', error);
      }
    );
  }

  // get user toltip name
  getOpenUserNameTooltip(taskRow, Open: string): string {
    return (this.tooltipUserNameOpen = taskRow.userName);
  }
  close(): void {
    this.dialogRef.close();
  }
}
