<!-- //borad tab start  -->

<div class="row containerHeight">
  <div class="col-md-6">
    <div class="drag-container">
      <div class="section-headingOpen">Open<span class="widgetCount">{{OpenTaskList.length}}</span></div>
      <div class="section-headingOpen1"></div>
      <div cdkDropList id="openList" #openList="cdkDropList" [cdkDropListData]="OpenTaskList"
        [cdkDropListConnectedTo]="[progresList,openList]" class="item-list MyAssigTaskList custom-scroll"
        (cdkDropListDropped)="drop($event)">
        <div class="item-box" *ngFor="let t of OpenTaskList ; let i=index" cdkDrag>
          <p (click)="getTaskDetailsById(t.taskId)" class="desc-space oy-hidden"
          appCustomManipulationText [innerHTML]="t.taskDescription | noSanitize"></p>
          <div class="row myTaskOption">
            <div class="col-2">
              <div  *ngIf="t.taskDueDate === null else showDeuDate">No due date</div>
              <ng-template #showDeuDate>
                <div  [class.dueDatemyTask]="t.isTaskOverDue === true">{{t.taskDueDate | date: 'MMM dd'}}</div>
              </ng-template>
            </div>
            <div class="col-2">
              <mat-chip #ddTrigger="matMenuTrigger" [ngClass]="{
                  'inProgressstatus':t.taskStatus == 'In Progress'
                }" [matMenuTriggerFor]="ss" (click)="taskStatusValue(t)"
                class="myTaskChip chipColorStatus chipShadow taskOption mr-2" selected>{{t.taskStatus}}
              </mat-chip>
              <mat-menu #ss="matMenu" [overlapTrigger]="false">
                <div (click)="cancelRemoveClick($event)" class="userStatusDropDown">
                  <mat-form-field class="mat-sizes">
                    <mat-select placeholder="Selected Status" [(value)]="selectedStatus" (selectionChange)="getTaskStatusValue($event,t)">
                      <mat-option *ngFor="let dl of dataList" [value]="dl.name">
                        {{dl.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-menu>
            </div>
            <div class="col-2">
              <div class="center-vertically">
                <span class="userIcon" matTooltipClass='user-tooltip' matTooltip="{{tooltipUserName}}"
                  [tooltip]="getUserNameTooltip(t)" matTooltip="Assign user">
                  {{t.userName | shortName}}</span>
              </div>
            </div>
            <div class="col-2 text-justify">
              <mat-icon (click)="taskPriorityValue(t)" #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="pp" [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }" aria-hidden="false"  matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}"
              class="taskIconColor" color="primary" aria-label="Example home icon">flag</mat-icon>
              <mat-menu #pp="matMenu" [overlapTrigger]="false">
                <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                  <mat-form-field class="mat-sizes">
                    <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                      (selectionChange)="getTaskPriorityValue($event,t)">
                      <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                        {{pp.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-menu>
            </div>
            <div class="col-4 text-justify">
              <div class="taskGroupName">{{t.groupName}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="col-md-6">
    <div class="drag-container">
      <div class="section-headingInprogres text-dark">In Progress <span class="widgetCount">{{InProgressTaskList.length}}</span></div>
      <div class="section-headingInprogres1"></div>
      <div cdkDropList id="progresList" #progresList="cdkDropList" [cdkDropListData]="InProgressTaskList"
        [cdkDropListConnectedTo]="[openList,progresList]" class="item-list MyAssigTaskList custom-scroll"
        (cdkDropListDropped)="drop($event)">
        <div class="item-box " *ngFor="let t of InProgressTaskList; let i=index" cdkDrag>
          <p (click)="getTaskDetailsById(t.taskId)" class="desc-space oy-hidden"
          appCustomManipulationText [innerHTML]="t.taskDescription | noSanitize"></p>
          <div class="row myTaskOption">
            <div class="col-2">
              <div   *ngIf="t.taskDueDate === null else showDeuDate">No due date</div>
              <ng-template #showDeuDate>
                <div [class.dueDatemyTask]="t.isTaskOverDue === true">{{t.taskDueDate | date: 'MMM dd'}}</div>
              </ng-template>
            </div>
            <div class="col-3">
              <mat-chip #ddTrigger="matMenuTrigger" [ngClass]="{
                  'inProgressstatus':t.taskStatus == 'In Progress'
                }" [matMenuTriggerFor]="ss" (click)="taskStatusValue(t)"
                class="myTaskChip chipColorStatus chipShadow taskOption mr-2" selected>{{t.taskStatus}}
              </mat-chip>
              <mat-menu #ss="matMenu" [overlapTrigger]="false">
                <div (click)="cancelRemoveClick($event)" class="userStatusDropDown">
                  <mat-form-field class="mat-sizes">
                    <mat-select placeholder="Selected Status" [(value)]="selectedStatus" (selectionChange)="getTaskStatusValue($event,t)">
                      <mat-option *ngFor="let dl of dataList" [value]="dl.name">
                        {{dl.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-menu>
            </div>
            <div class="col-2">
              <div class="center-vertically" *ngIf="t.userName else showUserMsg">
                <span class="userIcon" matTooltipClass='user-tooltip' matTooltip="{{tooltipUserName}}"
                  [tooltip]="getUserNameTooltip(t)" matTooltip="Assign user"  (click)="taskUserChange(t)">
                  {{t.userName | shortName}}</span>
              </div>
              <ng-template #showUserMsg>
                <div class="center-vertically">
                  <mat-icon class="delete2 defaultIconsClr mt-1" (click)="taskUserChange(t)" #ddTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="aa" matTooltip="Assign user">
                    person</mat-icon>
                </div>
              </ng-template>
            </div>
            <div class="col-2 text-justify">
              <mat-icon #ddTrigger="matMenuTrigger"
              [matMenuTriggerFor]="pp" (click)="taskPriorityValue(t)" [ngClass]="{'taskpriorityUrgent': getPriority(t.taskPriority) === 'Urgent', 'taskpriorityHigh' : getPriority(t.taskPriority) === 'High', 'taskpriorityNormal' : getPriority(t.taskPriority) === 'Normal' }" aria-hidden="false"
              class="taskIconColor" matTooltip="{{getPriority(t.taskPriority) +' '+ 'Priority'}}" color="primary" aria-label="Example home icon">flag</mat-icon>
              <mat-menu #pp="matMenu" [overlapTrigger]="false">
                <div (click)="cancelRemoveClick($event)" class="userTaskDropDown">
                  <mat-form-field class="mat-sizes">
                    <mat-select placeholder="Selected Status" [(value)]="selectedPriority"
                      (selectionChange)="getTaskPriorityValue($event,t)">
                      <mat-option *ngFor="let pp of taskPriorityList" [value]="pp.name">
                        {{pp.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-menu>
            </div>
            <div class="col-3">
              <div class="taskGroupName">{{t.groupName}}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- //borad tab end  -->
